<template>
  <section class="products">
    <v-img
      v-if="category"
      :src="category.banner"
      max-height="420"
    >
      <v-layout
        align-center
        fill-height
        justify-center
        style="position: relative; text-align: center;"
      >
      </v-layout>
    </v-img>
    <v-container  grid-list-md text-center class="ctk-div" style="padding-top:10px !important;">
      <div>
        <v-breadcrumbs :items="breadcrumbs" divider=" > "></v-breadcrumbs>
      </div>
      <h3 style="text-align: left;margin-bottom: 10px;">产品</h3>
      <v-layout wrap>
        <v-flex
          v-for="(product, i) in products"
          :key="i"
          xs12
          pa-2
          :class="products.length>3?'md3 ctk-jx-category':'md4 ctk-jx-category'"
        >
          <v-card
            class="mx-auto ctk-product-card"
          >
            <a :href="'/products/detail/' + product.id">
              <v-img
                :src="product.img"
                aspect-ratio="1"
                class="ctk-product-img"
              ></v-img>
              <div class="ctk-product-text">
                <h1>{{product.title}}</h1>
                <p>{{product.profile}}</p>
              </div>
            </a>
          </v-card>
        </v-flex>
      </v-layout>
      <h3 style="text-align: left;margin: 20px 0;">应用技术</h3>
      <v-layout wrap style="margin-bottom: 30px;">
        <v-flex
          v-for="(tech, ti) in techs"
          :key="ti"
          xs12
          pa-2
          md4
          class="ctk-jx-category"
        >
          <v-card
            class="mx-auto ctk-case-category-card"
          >
            <a :href="'/tech/' + tech.id">
              <v-img
                :src="tech.img"
                aspect-ratio="1"
                class="ctk-case-category-img"
              ></v-img>
              <div class="ctk-cat-text">
                <h1>{{tech.title}}</h1>
              </div>
            </a>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </section>
</template>
<script>
export default {
  data: () => ({
    breadcrumbs: [],
    category: null,
    products: null,
    techs: null,
    pageId: 2
  }),
  created () {
    document.querySelector('#menu_product').classList.add('v-btn--active')
    this.getCategory(this.$route.params.pid)
    this.getProducts(this.$route.params.pid)
  },
  mounted () {
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        this.info.banners = val.banner[this.pageId]
      },
      deep: true
    }
  },
  computed: {
    info () {
      let banners = {}
      banners[this.pageId] = []
      if (this.$store.getters.companyInfo.banner) {
        banners = this.$store.getters.companyInfo.banner[this.pageId]
      }
      return {
        banners: banners
      }
    }
  },
  methods: {
    getCategory (id) {
      this.https.get('product_category', { id: id }).then(response => {
        if (response.code === 0) {
          this.category = response.data.cat
          this.techs = response.data.tech
          this.breadcrumbs = [
            { text: '首页', disabled: false, href: '/' },
            { text: '产品系统', disabled: true },
            { text: this.category.name, disabled: true }
          ]
        }
      })
    },
    getProducts (cid) {
      this.https.get('products', { cid: cid }).then(response => {
        if (response.code === 0) {
          this.products = response.data.data
          if (this.products.length > 3) {
          }
        }
      })
    }
  }
}
</script>
<style>
  .v-application ul, .v-application ol {
    padding-left: 0;
  }

  .ctk-product-card {
    height:400px;
    width: 100%;
    margin: 10px;
    background-color: #015CA8 !important;
    display: inline-block;
    background-size: cover;
    position: relative;
    cursor: pointer;
    transition: all 0.4s ease-out;
    color: white;
    overflow: hidden;
  }

  .ctk-product-card .ctk-product-img {
    border: 1px solid #eee !important;
    width:100%;
    height: 200px;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    z-index: 0;
    transition: all 0.4s ease-out;
  }

  .ctk-product-card .ctk-product-text {
    background: #fff;
    margin-top: 200px;
    height: 300px;
    z-index: 99;
  }
  .ctk-product-card .ctk-product-text p {
    padding: 10px 20px;
    color: #303133;
    text-align: left;
  }

  .ctk-product-card h1 {
    fon-size:20px;
    font-weight: 400;
    color: #303133;
    margin: 0;
    padding-top:30px;
  }
  .ctk-product-card:hover {
    outline: none !important;
    background-color:#99aeff;
    box-shadow: 0px 35px 77px -17px rgba(0, 0, 0, 0.64);
  }

  .ctk-product-card:hover .ctk-product-img {
    border: none;
    opacity: 0.3;
    height: 500px;
  }
  .ctk-product-card:hover .ctk-product-text {
    color: #fff;
    background: none;
  }
  .ctk-product-card:hover .ctk-product-text p{
    color: #fff;
  }
  .ctk-product-card:hover .ctk-product-text h1{
    color: #fff;
  }
  .ctk-jx-category .v-card {
    box-shadow: 0 6px 8px rgba(20,19,19,0.04) !important;
  }
  .ctk-case-category-card {
    outline: none !important;
  }
</style>
